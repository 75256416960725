.landing {
  position: relative;
  min-height: 88vh;
  margin-top: 70px;
  width: 100%;

  .bg-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto; /* Adjust as needed */
    height: auto; /* Adjust as needed */

    .bg-content-color {
      border-radius: var(--No, 526px);
      background: #00a3ff;
      filter: blur(297px);
      width: 526px;
      height: 526px;
      flex-shrink: 0;

      @media (max-width: 600px) {
        width: 300px; /* Adjust the width for mobile view */
        height: 300px; /* Adjust the height proportionally */
        border-radius: var(
          --No,
          300px
        ); /* Adjust the border-radius accordingly */
      }
    }
  }
}

.form-group {
  label {
    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }
}

@-webkit-keyframes loader {
  0% {
    height: 10px;
    opacity: 0.9;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(-11px);
  }
}
@-moz-keyframes loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
    -moz-transform: translateY(0);
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0.3;
    -moz-transform: translateY(-11px);
  }
}
.chat-loader {
  text-align: center;
  display: flex;
  gap: 10px;
  span {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 10px;
    background: grey;
    border-radius: 50px;
    -webkit-animation: loader 0.5s infinite alternate;
    -moz-animation: loader 0.5s infinite alternate;
    &:nth-of-type(2) {
      -webkit-animation-delay: 0.2s;
      -moz-animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      -webkit-animation-delay: 0.3s;
      -moz-animation-delay: 0.3s;
    }
  }
}
